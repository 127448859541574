export enum Environment {
  local = 'local',
  dev = 'dev',
  test = 'test',
  sit = 'sit',
  uat = 'uat',
  prod = 'prod',
}

export enum Category {
  cloud = 'cloud',
  labs = 'labs',
  legacy = 'legacy',
}

export type Link = {
  name: string
  link?: string
}

export interface Application {
  name: string
  category: Category
  description?: string
  links: {
    launch: { [key in Environment]?: Link[] | string }
    support: Link[]
  }
}
