import * as React from 'react'
import { Pages } from 'constants/pages'
import { NotificationsContextValue } from './notifications.interface'

const notificationContexts: Map<string, React.Context<NotificationsContextValue>> = new Map()

export const getNotificationsContext = (
  id: Pages | 'global'
): React.Context<NotificationsContextValue> => {
  if (!notificationContexts.has(id)) {
    notificationContexts.set(
      id,
      React.createContext<NotificationsContextValue>({
        pageId: id,
        notifications: [],
        showNotification: () => {},
        showRecoverableError: () => {},
        closeNotification: () => {},
      })
    )
  }

  return notificationContexts.get(id) as React.Context<NotificationsContextValue>
}
