import config from 'config/environment'
import tokenService from './token.service'
import locationService from './location.service'
import { IAuthStrategy } from 'interfaces'

const localAuthenticate = () => {
  const { token } = locationService.getSearchParams()

  if (token) {
    tokenService.save(String(token))
    locationService.redirect('/')
  } else {
    locationService.redirect(
      `${config.auth_url}?redirect=${encodeURIComponent(window.location.href)}&strategy=manual`
    )
  }
}

export const localAuthStrategy: IAuthStrategy = { authenticate: localAuthenticate }
