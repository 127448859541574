import { parse } from 'qs'

interface ILocationService {
  redirect(to: string): void
  getCurrentHref(): string
  reload(): void
}

export class LocationService implements ILocationService {
  constructor(private location: Location) {}

  getCurrentHref() {
    return this.location.href
  }

  getSearchParams() {
    return parse(window.location.search, { ignoreQueryPrefix: true })
  }

  redirect(to: string) {
    this.location.href = to
  }

  reload() {
    this.location.reload()
  }
}

const locationService = new LocationService(window.location)
export default locationService
