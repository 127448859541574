import { Environment } from 'entities/common'
import tokenService from './token.service'
import config from 'config/environment'
import { localAuthStrategy } from './local-authenticate.strategy'
import { defaultAuthStrategy } from './default-authenticate.strategy'
import { embeddedAuthStrategy, isEmbeddedAuthAvailable } from './embedded-authenticate.strategy'
import locationService from './location.service'
import { IAuthService, IAuthStrategy } from 'interfaces'

export class AuthService implements IAuthService {
  async authenticate() {
    const authStrategy = await this.getAuthStrategy()
    authStrategy.authenticate()
  }

  invalidate() {
    tokenService.delete()
  }

  logout() {
    locationService.redirect('/auth/logout/')
  }

  private async getAuthStrategy(): Promise<IAuthStrategy> {
    if (config.environment === Environment.local) {
      return localAuthStrategy
    }

    const isEmbedded = await isEmbeddedAuthAvailable()
    if (isEmbedded) {
      return embeddedAuthStrategy
    }

    return defaultAuthStrategy
  }
}

export const authService = new AuthService()
