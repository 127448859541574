import * as React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

const Dashboard = React.lazy(
  () => import(/* webpackChunkName: "dashboard" */ '../../pages/dashboard/Dashboard.component')
)

const Settings = React.lazy(
  () => import(/* webpackChunkName: "settings" */ '../../pages/settings/Settings.component')
)

const Glossaries = React.lazy(
  () => import(/* webpackChunkName: "glossaries" */ '../../pages/glossaries/Glossaries.component')
)

// TODO: Check if page title change is required when adding new routes
export const Routes: React.FC = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/settings" component={Settings} />
      <Route path="/glossaries" component={Glossaries} />
    </Switch>
  </Router>
)
